import axios, { AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import uuid from 'uuid'
import {
  AutoliittoSeparatorLine,
  ButtonContainer,
} from '../../containers/TowingRecordPage/components/routes/subforms/styled'
import { getStoredLogin } from '../../graphql-client/storedState'
import { Button } from '../Button'
import { BigButtonSection, Section, Text } from '../layout'
import { Modal } from '../Modal'
import { FullscreenModal } from '../ModalPage'
import { setErrorNotification, setSuccessNotification, setUnauthorizedNotification } from '../notification'
import { setGenericErrorNotification } from '../notification/genericResponseErrorNotification'
import { Loading } from '../responses/Loading'
import { CameraView } from './Camera'
import { Thumbnail } from './Thumbnail'
import { TowingRecordFile, TowingRecordFileResponse } from './types'

interface ImagesProps {
  onClose: () => void
  towingRecordId: number
  vehicleRegNo?: string
}

export const Images: React.FC<ImagesProps> = ({ onClose, towingRecordId, vehicleRegNo }) => {
  const [images, setImages] = useState<TowingRecordFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [showCamera, setShowCamera] = useState<boolean>(false)
  const [imageToDelete, setImageToDelete] = useState<TowingRecordFile | null>(null)
  const [cameraImages, setCameraImages] = useState<File[]>([])
  const fileInputRef = useRef<HTMLInputElement>(null)

  const onImageChange = async (e: any) => {
    const files = Array.from(e.target.files)
    const newImages = files.map((f: any) => {
      return {
        id: null,
        type: 'photo',
        uuid: '',
        description: '',
        url: URL.createObjectURL(f),
        isPublic: false,
        mimeType: '',
        extension: '',
        base64: '',
        createdAt: null,
      } as TowingRecordFile
    })
    setImages([...images, ...newImages])
  }

  const handleTakePhoto = (dataUri: string) => {
    const extension = '.png'
    const name = uuid() + extension
    const newImage = {
      id: null,
      type: 'photo',
      uuid: '',
      description: '',
      url: dataUri,
      isPublic: false,
      mimeType: '',
      extension: '',
      base64: '',
      createdAt: null,
      name,
    } as TowingRecordFile
    setImages([...images, newImage])
    const file = new File([dataURItoBlob(dataUri)], name, { type: 'image/png' })
    setCameraImages([...cameraImages, file])
    setShowCamera(false)
  }

  const handleUpload = async () => {
    try {
      if (
        typeof fileInputRef !== 'undefined' &&
        fileInputRef !== null &&
        typeof fileInputRef.current !== 'undefined' &&
        fileInputRef.current !== null
      ) {
        setLoading(true)
        const data = new FormData()
        const files = fileInputRef.current.files

        if (files) {
          for (const file of files) {
            data.append(file.name, file)
          }

          for (const file of cameraImages) {
            data.append(file.name, file)
          }

          const response = await upload(data, towingRecordId)

          handleResponse(response)

          const images = response.data.data.map((f: TowingRecordFile) => {
            const blob = b64toBlob(f.base64, f.mimeType)
            const blobUrl = URL.createObjectURL(blob)
            f.url = blobUrl

            return f
          })
          setImages(images)
          setCameraImages([])
        }
      }
    } catch (e) {
      setErrorNotification('Virhe!', 'Virhe kuvien lataamisessa palvelimelle')
    } finally {
      setLoading(false)
    }
  }

  const handleFileInputClick = () => {
    if (
      typeof fileInputRef !== 'undefined' &&
      fileInputRef !== null &&
      typeof fileInputRef.current !== 'undefined' &&
      fileInputRef.current !== null
    )
      fileInputRef.current.click()
  }

  const toggleCamera = () => {
    setShowCamera(!showCamera)
  }

  const handleDeleteConfirm = (img: TowingRecordFile) => {
    setImageToDelete(img)
  }

  const handleDelete = async (img: TowingRecordFile) => {
    if (img.id) {
      setLoading(true)
      await deleteFileFromServer(img.id)
      setLoading(false)
    }

    setImages(i => i.filter(image => image.url !== img.url))
    setCameraImages(i => i.filter(image => image.name !== img.name))
  }

  useEffect(() => {
    setLoading(true)

    const cancelToken = axios.CancelToken
    const source = cancelToken.source()

    axios
      .get(`${process.env.REACT_APP_REST_API_URL}/files/${towingRecordId}`, {
        headers: {
          Authorization: `Bearer ${getStoredLogin()}`,
        },
        cancelToken: source.token,
      })
      .then((response: AxiosResponse<TowingRecordFileResponse>) => {
        const images = response.data.data.map(f => {
          const blob = b64toBlob(f.base64, f.mimeType)
          const blobUrl = URL.createObjectURL(blob)
          f.url = blobUrl

          return f
        })
        setImages(images)
      })
      .finally(() => {
        setLoading(false)
      })

    return () => {
      source.cancel()
    }
  }, [towingRecordId])

  const label = vehicleRegNo ? `${vehicleRegNo} kuvat` : 'Kuvat'

  const hasPendingChanges = images.filter(i => i.id === null).length > 0

  return (
    <>
      <FullscreenModal onClose={onClose} label={label}>
        {imageToDelete && (
          <Modal
            label={`Poistetaanko kuva`}
            message="Vahvista kuvan poistaminen"
            close={() => {
              setImageToDelete(null)
            }}
            onConfirm={() => {
              handleDelete(imageToDelete)
            }}
            closeLabel="Peruuta"
            border="1px solid"
          />
        )}
        <BigButtonSection>
          <Button label="Ota kuva" category="on" size="m" maxWidth="100%" onClick={toggleCamera} />
          <Button label="Lataa kuva" onClick={handleFileInputClick} category="on" size="m" maxWidth="100%" />
        </BigButtonSection>

        <HiddenInput
          type="file"
          ref={fileInputRef}
          multiple
          accept="image/*"
          onChange={onImageChange}
          id="file-upload"
        />
        <AutoliittoSeparatorLine marginTop="1rem" marginBottom="1rem" />
        {images.length > 0 && (
          <Section height="auto">
            {images.map(img => (
              <Thumbnail
                src={img.url}
                key={img.base64}
                onDelete={() => {
                  handleDeleteConfirm(img)
                }}
              />
            ))}
          </Section>
        )}
        {images.length === 0 && <Text>Kuormakirjalle ei ole lisätty kuvia</Text>}
        {loading && <Loading />}
        {showCamera && <CameraView onClose={toggleCamera} onTakePhoto={handleTakePhoto} />}
        {hasPendingChanges && (
          <>
            <Margin />
            <ButtonContainer floatAtBottomNoMenu>
              <Button
                category="save"
                size="l"
                label={'Tallenna'}
                mobileLabel="Tallenna"
                maxWidth="100%"
                onClick={handleUpload}
              ></Button>
            </ButtonContainer>
          </>
        )}
      </FullscreenModal>
    </>
  )
}

const HiddenInput = styled.input`
  display: none;
`

const upload = async (data: FormData, towingRecordId: number) => {
  const response = await axios.post(`${process.env.REACT_APP_REST_API_URL}/files/upload/${towingRecordId}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${getStoredLogin()}`,
    },
  })

  return response
}

const deleteFileFromServer = async (id: number) => {
  return await axios.delete(`${process.env.REACT_APP_REST_API_URL}/files/${id}`, {
    headers: {
      Authorization: `Bearer ${getStoredLogin()}`,
    },
  })
}

const handleResponse = (response: AxiosResponse<any>) => {
  switch (response.status) {
    case 401:
      return setUnauthorizedNotification()
    case 413:
      return setErrorNotification('Virhe', 'Tiedosto on liian suuri')
    case 200:
      return setSuccessNotification('Valmis', 'Kuvat ladattu palvelimelle')
    default:
      return setGenericErrorNotification('Virhe', 'Kuvan lataaminen palvelimelle epäonnistui')
  }
}

const b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}

function dataURItoBlob(dataURI: string) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1])

  // separate out the mime component
  var mimeString = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length)

  // create a view into the buffer
  var ia = new Uint8Array(ab)

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString })
  return blob
}

const Margin = styled.div`
  margin-top: calc(72px + 1rem);
`
